import React, { useState } from "react"
import loadable from '@loadable/component';
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal"

import './assets/styles/_index.scss'
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");
const LandingPagePagination = loadable(() => import("../InnerPagination/LandingPagePagination"));
const { usePagination } = require("@starberry/gatsby-theme-utils/Hooks/UsePagination")

const ReviewsWall = (props) => {

    const data = useStaticQuery(graphql`
  query {
    allGoogleReviews(filter: {starRating: {nin: ["ONE", "TWO", "THREE", "FOUR"]}}) {
        totalCount
        edges {
            node {
                id
                reviewer {
                displayName
                }
                comment
                starRating
            }
        }
    }
  }
`);
    const reviewsList = data.allGoogleReviews.edges;


    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
    const starRating = (rating) => {
        let starCont = [];
        for (let i = 0; i < rating; i++) {
            starCont.push(<i className='icon icon-star'></i>);
        }
        return starCont;
    }


    // for pagination
    const itemsPerPage = 40
    const { currentItems, currentPage, setCurrentPage } = usePagination({
        items: reviewsList,
        itemsPerPage,
    })
    // for pagination

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const [reviewid, setReviewid] = useState(false)
    const handleShow = event => {
        setReviewid(event)
        setShow(true)
    }

    return (
        <div className='reviews-wall-wrapper section-p'>
            <Container>
                <div className='reviews-wall-heading-wrapper'>
                    {props.content && <ContentModule Content={props.content.data.content} />}
                </div>
                <div className='reviews-list-wrapper'>
                    {currentItems.map(({ node }, index) => {
                        return (
                            <React.Fragment>
                                {node.comment && <div className='review-card' key={index}>
                                    <div className='icon-wrapper'>
                                        {starRating(wordToNumber[node.starRating])}
                                    </div>

                                    <p className='comment'>{node?.comment &&
                                        node?.comment.length > 250
                                        ? node?.comment.substring(0, 250) + "... "
                                        : node?.comment}{node?.comment &&
                                            node?.comment.length > 250 && (
                                                <a
                                                    href="javascript:void(0);"
                                                    className="btn-text-link read-more"
                                                    onClick={() => handleShow(node?.id)}
                                                >
                                                    more
                                                </a>
                                            )}</p>

                                    <div className='author-details d-flex justify-content-center align-items-center'>
                                        <p>{node.reviewer?.displayName}</p>
                                        {/* <p>{node.position}</p> */}
                                    </div>
                                </div>}
                                {reviewid && reviewid == node.id && (
                                    <Modal
                                        show={show && reviewid == node.id}
                                        size="lg"
                                        centered={true}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                        dialogClassName="modal-60w modal-form reviewPopup"
                                    >
                                        <div className="review-wrap">
                                            <Modal.Header closeButton className="contact-close-btn">
                                                <Modal.Title className="w-100">
                                                    {node?.reviewer?.displayName}
                                                    <div className='icon-wrapper'>
                                                        {starRating(wordToNumber[node.starRating])}
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {/* <Rating starRating={node?.rating} /> */}
                                                <p className='comment'>{node?.comment}</p>
                                            </Modal.Body>
                                        </div>
                                    </Modal>
                                )}
                            </React.Fragment>
                        )
                    })}
                </div>
                <LandingPagePagination
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={reviewsList?.length}
                    setCurrentPage={setCurrentPage}
                />
            </Container>
        </div>
    )
}

export default ReviewsWall