import React from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../components/layout'
import Seo from "../components/seo"
import SafeHandsImg from "../images/safe_hands_img.png"

import ReviewsWall from '../components/ReviewsWall/ReviewsWall'
const Banner = loadable(() => import("../components/Banner/Banner"))
const SafeHands = loadable(() => import("../components/SafeHands/SafeHands"))
const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))

const Reviews = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Layout classname={PageData?.custom_css_classname} popularSearch={PageData?.select_popular_search?.title}>
            <Banner
                showBreadCrumb
                breadcrumbData={PageData.choose_menu[0]}
                pagename={PageData.title}
                {...PageData.banner}
                title={PageData.banner?.banner_title}
                id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms}
                btnPrimaryIconClass="icon-banner-valuation"
                btnPrimaryLightIconClass="icon-valuation-home"
            />

            {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_review_slider" && <SafeHands {...module} Img={SafeHandsImg} />}
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_reviews_all" && <ReviewsWall {...module} />}
                        {module.strapi_component === "page-modules.plain-content" && module.layout === "bg_color_center_align" && <SellLetHome {...module} />}
                    </>
                )
            }
            )}
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default Reviews

export const query = graphql`
    query ($page_id: String) {
      strapiPage(id: {eq: $page_id}) {
          ...PageFragment
          add_page_modules {
                ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                    ...GlobalModuleFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                    ...PlainContentFragment
                }
          }
      }
    }
  `